import React from "react"
import { Link } from "gatsby"
const AuthorInfo = ({ author }) => {
    const { name, slug, avatar } = author?.node || {};
    const AvatarImageUrl = avatar.url.split('?s=').join('?s=36&d=identicon&r=g')
    return (
        <div className="flex flex-row items-center gap-3 content-center justify-items-center my-4 flex-wrap">
            {author?.node?.name && (
                <div className="mb-0">
                    <Link to={`/author/${slug}`}
                        className="flex items-center gap-3 md:text-xl text-lg text-[#6C7180] no-underline">
                        {AvatarImageUrl && (
                            <picture alt={author?.node?.name}>
                                <source srcSet={`${AvatarImageUrl}`} type="image/webp" />
                                <img src={`${AvatarImageUrl}`} alt="Avatar"  className="w-8 h-8 rounded-full m-0 p-0"/>
                            </picture>
                        )}
                        <span>by <b className="font2">{name}</b></span></Link>
                </div>
            )}
        </div>
    )
}

export default AuthorInfo;