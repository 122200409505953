import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { Link } from "gatsby"
import BlogPostsPagination from "../components/blog/BlogPostsPagination"

const AuthorPage = (props) => {
  const { authorDetails } = props.data
  const { name, avatar, description, posts } = authorDetails
  return (
    <Layout>
      <div className="bg-[#0043DF] py-8 header_bg relative overflow-hidden ">
        <div className="max-w-7xl mx-auto px-4 md:py-16 py-8  mt-8" >
          <div className="w-full z-30 relative flex sm:flex-row flex-col items-center gap-10">
            <div>
              <div className="w-48 h-48 rounded-full border-[6px] border-white">
                <img src={avatar?.url} alt={name} className="rounded-full m-0 p-0 w-full" />
              </div>
              <div className="flex flex-row gap-3 justify-center items-center mt-4">
                <Link to='/'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" /></svg></Link>
                <Link to='/'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" viewBox="0 0 24 24"><path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" /></svg></Link>
                <Link to='/'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="#fff" width="28" height="28"><path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z" /></svg></Link>
              </div>
            </div>
            <div>
              <h3 className="text-base font1 text-[#fde047] uppercase">About Author</h3>
              {name && <h1 className="shrink py-4 text-white font1 md:text-5xl text-3xl tracking-tight mb-0">{name}</h1>}
              <p className="md:text-2xl text-xl text-white max-w-full md:leading-[40px]">{description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 md:py-16 py-8">                
          <BlogPostsPagination data={posts.nodes} name={name} />
      </div>
    </Layout>
  )
}

export const Head = props => {
  const { authorDetails } = props.data
  return (
    <Seo
      title={authorDetails?.name}
      description={authorDetails?.name}
    />
  )
}

export const query = graphql`
  query getAuthorDetails($id: String!) { 
    authorDetails:  wpUser(id: {eq: $id}) {
      name
      slug
      avatar {
        url
      }
      description
      posts {
          nodes {
            ...WpPostFragment
          }
      }
    }
  }
`

export default AuthorPage