import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from "../components/layout"
import BlogPostsPagination from "../components/blog/BlogPostsPagination"

const CategoryPage = (props) => {
    const { categoryDetails } = props.data
    const { name, posts } = categoryDetails
    return (
        <Layout>
            <div className="max-w-7xl mx-auto px-4 md:py-16 py-8 mt-8">                
                <BlogPostsPagination data={posts.nodes} name={name} />
            </div>
        </Layout>
    )
}

export const Head = props => {
    const { categoryDetails } = props.data
    return (
        <Seo
            title={categoryDetails?.name}
            description={categoryDetails?.name}
        />
    )
}

export const query = graphql`
  query getCategoryDetails($id: String!) { 
    categoryDetails:  wpCategory(id: {eq: $id}) {
      id
      name
      posts {
          nodes {
            ...WpPostFragment
          }
      }
    }
  }
`

export default CategoryPage