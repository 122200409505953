import React, { useState, useEffect, useRef } from 'react'
import BlogItem from "./BlogItem";

const BlogPostsPagination = ({ data, name }) => {
  const allPosts = data
  const pageLimit = 12
  const [list, setList] = useState([...allPosts.slice(0, pageLimit)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allPosts.length > pageLimit)
  const [searchTerm, setSearchTerm] = useState("");
  const loadRef = useRef()

  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  useEffect(() => {
    // Get the current URL's query parameters
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get('search'); // Get the 'search' param
    
    if (searchParam) {
        setSearchTerm(searchParam); // Optionally set it to the input field
    } 
}, []); // Empty dependency array ensures this runs only on mount

  useEffect(() => {
    if (loadMore && hasMore && !searchTerm) {
      const currentLength = list.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + pageLimit)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allPosts.length
    setHasMore(isMore)
  }, [list])

  // Filter posts based on search term
  const filteredPosts = searchTerm
    ? allPosts.filter((post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : list; // Show paginated list if no search term

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4 items-center'>
        <div className='col-span-3'>
          {name && <h1 className="shrink py-4 text-[#385041] font1 md:text-5xl text-3xl tracking-tight mb-0">Posts from {name}</h1>}
        </div>
        <div className="bg-white inline-flex items-center py-1 pl-4 pr-1 rounded-full max-w-5xl border border-[#d0d5dd]">
          <input type="email" placeholder="Search for a blog here..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full  bg-transparent  px-2 text-[#6c7180] text-base border-none outline-none" />
          <button className="text-white py-3 px-4 rounded-full focus:outline-none">
            <svg clipRule="evenodd" fillRule="evenodd" width="24" height="24" fill="#d0d5dd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero" /></svg>
          </button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 my-10">
        {list && !searchTerm && list.map((item) => <BlogItem key={item?.id} data={item} />)}
        {filteredPosts && searchTerm && filteredPosts.map((item) => (
          <BlogItem key={item?.id} data={item} />
        ))}
      </div>
      <div ref={loadRef} className="flex justify-center mt-12 mb-14">
        {hasMore && !searchTerm && (
          <button className="inline-block mx-auto px-4 py-2 text-white bg-blue-600 transition-colors duration-300 rounded-lg hover:bg-blue-700 focus:outline-none">Load More ...</button>
        )}
      </div>
    </>
  )
}
export default BlogPostsPagination