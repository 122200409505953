import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby";
import AuthorInfo from "./AuthorInfo";

const BlogItem = ({ data }) => {
    const { title, excerpt, slug, author, featuredImage, categories } = data
    return (
        <>
            <div className="w-full max-w-md mx-auto border border-[#d0d5dd] rounded-xl z-20 relative">
            <Link to={`/${slug}`}><GatsbyImage image={getImage(featuredImage.node)} alt={title} className="rounded-t-xl" /></Link>
                <div className="p-3">
                    <div className="flex flex-row gap-2">
                        {categories && categories?.nodes && categories?.nodes.slice(0, 2).map((cat) => (
                            <Link to={`/${cat?.slug}`} className="no-underline text-white text-xs py-1 px-5 bg-[#004CFF] rounded-full inline-block font2 mb-6" key={cat?.id}>{cat?.name}</Link>
                        ))}
                    </div>
                    <h3><Link to={`/${slug}`} className="text-2xl text-[#202837] no-underline inline-block font1 mb-3 tracking-tight">{title}</Link></h3>
                    <div className="text-base text-[#202837] mb-0" dangerouslySetInnerHTML={{ __html: excerpt }} />
                    {author && author?.node && <AuthorInfo author={author} />}

                    <Link to={`/${slug}`} className="text-[#004CFF] no-underline font-semibold flex items-center gap-2 mb-2">Read Now
                        <svg width={24} height={24} fill="#004CFF" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                    </Link>
                </div>
            </div>
        </>
    )
}
export default BlogItem

export const query = graphql`
  fragment WpPostFragment on WpPost {
    id
    slug
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    author {
    node {
        id
        name
        slug
        avatar {
        url
        }
    }
    }
    categories {
    nodes {
        id
        slug
        name
    }
    }
    featuredImage {
    node {
        gatsbyImage(layout: CONSTRAINED, width: 600)
    }
    }
  }
`