import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import { graphql, Link } from "gatsby";
import * as cheerio from "cheerio";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MoreLikeSection from "../components/blog/MoreLikeSection";

const Blog = (props) => {
  const { postDetails, AllPosts } = props.data;
  const { title, content, author, featuredImage, categories, modified } = postDetails;

  const otherPosts = AllPosts.edges
    .sort(() => Math.random() - Math.random())
    .slice(0, 4);

  // Utility to generate unique IDs for headings
  function createId($, title) {
    let id = $(title).attr("id");

    if (!id) {
      id = $(title)
        .text()
        .toLowerCase()
        .replace(/[^a-z_0-9]+/gi, "-")
        .replace(/-+/g, "-");
    }

    return id;
  }

  function UniqueId() {
    const tempMap = {};
    return (el) => {
      if (tempMap[el]) {
        tempMap[el] = tempMap[el] + 1;
        const result = `${el}-${tempMap[el]}`;
        tempMap[result] = 1;
        return result;
      } else {
        tempMap[el] = 1;
        return el;
      }
    };
  }

  function groupHeadings(index, grouping, headings) {
    if (index < headings.length) {
      const nextHeading = headings[index];

      if (grouping.length) {
        const prevHeading = grouping.slice().pop();

        try {
          if (nextHeading.depth > prevHeading.depth) {
            prevHeading.items = prevHeading.items || [];
            return groupHeadings(index, prevHeading.items, headings);
          } else if (nextHeading.depth === prevHeading.depth) {
            grouping.push({ ...nextHeading });
            return groupHeadings(++index, grouping, headings);
          } else {
            throw { index: index, heading: nextHeading };
          }
        } catch (higherHeading) {
          if (higherHeading.heading.depth === prevHeading.depth) {
            grouping.push({ ...higherHeading.heading });
            return groupHeadings(++higherHeading.index, grouping, headings);
          } else {
            throw higherHeading;
          }
        }
      } else {
        grouping.push({ ...nextHeading });
        groupHeadings(++index, grouping, headings);
      }
    }

    return grouping;
  }

  function createTableOfContents(content) {
    const $ = cheerio.load(content);
    const titles = $("h2");
    const getUniqueId = UniqueId();


    const headings = Array.from(titles).map((title) => {
      const depth = parseInt($(title).prop("tagName").substr(1), 10);
      const id = createId($, title);
      return { url: `#${getUniqueId(id)}`, title: $(title).text(), depth };
    });

    const reduced = groupHeadings(0, [], headings);
    return { items: reduced };
  }

  const tocdata = createTableOfContents(content);
  const renderTableOfContentItems = (items) => {
    return (
      <ol className="p-0 sidelink mb-4" style={{ marginLeft: "12px" }}>
        {items.map(({ url, title, items }, index) => (
          <li key={url} className="!text-base text-[#202837]">
            <p className="!text-base text-[#202837]"><a href={url} className="!text-base text-[#202837] no-underline">
              {title}
            </a></p>
            {items && items.length && renderTableOfContentItems(items)}
          </li>
        ))}
      </ol>
    );
  };
  const TableOfContent = (toc, className) => {
    return <div className="w-full">{toc?.items && renderTableOfContentItems(toc?.items)}</div>;
  };

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Set isClient to true once component mounts
    setIsClient(true);

    // Load OptinMonster script
    // const loadOptinMonster = () => {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.src = 'https://a.omappapi.com/app/js/api.min.js';
    //   script.async = true;
    //   script.dataset.user = '220803';
    //   script.dataset.campaign = 'q4ta7ugn5kkf6thjovuk';
      
    //   // Only append if script doesn't already exist
    //   if (!document.querySelector('script[src="https://a.omappapi.com/app/js/api.min.js"]')) {
    //     document.head.appendChild(script);
    //   }
    // };

    // // Delay script loading slightly to ensure DOM is ready
    // setTimeout(loadOptinMonster, 1000);

    // Cleanup function
    return () => {
      const existingScript = document.querySelector('script[src="https://a.omappapi.com/app/js/api.min.js"]');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  // Only render the container if we're on the client side
  if (!isClient) {
    return null; // or a loading state
  }

  const replaceContent = (content) => {
    return parse(content, {
      replace: (domNode) => {
        if (domNode.name === "a") {
          const newHref = customizeLink(domNode.attribs.href);
          if (newHref.startsWith("/")) {
            domNode.attribs.href = `https://www.economize.cloud/blog${newHref}`;
          }
          else
          {
            domNode.attribs.href = newHref;
          }          
        }
      },
    });
  };
  const customizeLink = (href) => {
    // Example customization logic
    if (href.includes("economizecloud.wpenginepowered.com")) {
      return href.replace("https://economizecloud.wpenginepowered.com", "https://www.economize.cloud/blog");
    }
    
    return href; // Default: return unchanged link
  };

  return (
    <Layout>
      <div className="w-full md:py-20 py-8">
        <div className="max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-8 md:mt-8 mt-12">
          <div>
            <div className="flex flex-row gap-2 mb-6 flex-wrap">
              {categories &&
                categories?.nodes &&
                categories?.nodes.map((cat) => (
                  <h5
                    className="text-white text-xs py-1 px-5 bg-[#004CFF] rounded-full inline-block font2"
                    key={cat?.id}
                  >
                    <Link to={`/${cat?.slug}`} className="text-white no-underline">
                      {cat?.name}
                    </Link>
                  </h5>
                ))}
            </div>

            <h1 className="xl:text-[44px] sm:text-4xl text-[28px] lg:leading-[1.2] font1 tracking-tight mb-4">{title}</h1>
            {/* <p className="sm:text-xl text-lg text-[#6C7180] " dangerouslySetInnerHTML={{ __html: excerpt }} /> */}
            <div className="flex my-4 items-center gap-3 flex-wrap">
            {modified && <p className="text-sm text-[#202837] mb-0 font1">{modified}</p>}
              {author?.node?.name && <p className="text-sm text-[#4C5463] mb-0">by   <Link to={`/author/${author?.node?.slug}`} className="text-sm text-[#4C5463] no-underline mb-0">{author?.node?.name}</Link></p>}
              <p className="text-sm text-[#4C5463] mb-0">8 mins Read</p>
            </div>
            <div className="flex gap-3 items-center mt-5">
              <Link
                href="https://www.economize.cloud/schedule-a-demo"
                className="text-white bg-[#004CFF] no-underline font2 rounded-md py-2 px-5 flex items-center gap-2 text-center"
              >
                <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
                    <path d="M7.421 4.356A1.25 1.25 0 005.5 5.411v5.178a1.25 1.25 0 001.921 1.055l4.069-2.59a1.25 1.25 0 000-2.109L7.42 4.356zM10.353 8L7 10.134V5.866L10.353 8z" />
                    <path d="M8 0a8 8 0 100 16A8 8 0 008 0zM1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z" />
                  </g>
                </svg>
                Demo{" "}
              </Link>
              <Link
                href="https://www.economize.cloud/signup"
                className="text-[#202837] border border-[#D0D5DD] no-underline font2 rounded-md py-2 px-5"
              >
                Sign Up{" "}
              </Link>
            </div>
          </div>
          <div>
            <GatsbyImage
              image={getImage(featuredImage.node)} 
              alt={title}
              loading="eager"
              priority={true} 
              width={600}
              height={340}
            />
          </div>
        </div>
        <div className="w-full md:py-12 py-8">
          <div className="max-w-7xl mx-auto px-4 grid lg:grid-cols-7 grid-cols-1 gap-6">
            <div className='col-span-2 hidden md:block md:visible invisible'>
              <div className="lg:sticky lg:top-[5.5rem] toc-content">
                <h3 className="font1 text-xl flex items-center justify-between gap-2 py-2 px-4 rounded-t-lg bg-[#004CFF33]">
                  Table of Contents{" "}
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#001975" viewBox="0 0 24 24">
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                  </svg>
                </h3>
                <div className="border border-[#004CFF33] rounded-b-lg py-4 px-2 pb-0">
                  {tocdata && TableOfContent(tocdata, "w-full")}
                </div>
                <div id="om-q4ta7ugn5kkf6thjovuk-holder"></div>
              </div>
            </div>
            <div className="col-span-4">
              {/* <div dangerouslySetInnerHTML={{ __html: content }} className="text-[#202837] blog_content" /> */}
              <div className="text-[#202837] blog_content prose prose-p:text-base prose-p:leading-2xl prose-p:text-[#202837] prose-p:mb-xl prose-p:max-w-full">{replaceContent(content)}</div>
              <div id="om-nlg72so1h2i64qmxpqoy-holder"></div>
              <div className="border-t border-b py-4 mt-5 inline-block author_info">
                <p className="md:leading-7 text-[17px] mb-8 text-[#202837] font-medium max-w-full">
                  “{author?.node?.description}”
                </p>
                <div className="flex flex-row items-center gap-3 content-center justify-items-center">
                  <Link to={`/author/${author?.node?.slug}`}>
                    <img
                      src={author?.node?.avatar?.url}
                      alt={author?.node?.name}
                      className="m-0 p-0 w-16 h-16 rounded-full"
                    />
                  </Link>
                  <p className="author p-0 m-0">
                    <Link
                      to={`/author/${author?.node?.slug}`}
                       className="text-lg mb-0 text-[#6C7180] font-extrabold no-underline"
                    >
                      {author?.node?.name}
                    </Link>
                  </p>
                </div>
              </div>
              <MoreLikeSection data={otherPosts} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = (props) => {
  const { postDetails } = props.data;

  return <Seo title={postDetails?.title} description={postDetails?.title} />;
};

export const query = graphql`
  query getPostDetails($id: String!) {
    AllPosts: allWpPost(filter: { id: { ne: $id } }) {
      edges {
        node {
          id
          slug
          title
          featuredImage {
            node {
              gatsbyImage(layout: CONSTRAINED, width: 440)
            }
          }
        }
      }
    }
    postDetails: wpPost(id: { eq: $id }) {
      title
      content
      categories {
        nodes {
          id
          name
          slug
        }
      }
      author {
        node {
          slug
          name
          description
          avatar{
          url
          }
        }
      }
      modified(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          gatsbyImage(layout: CONSTRAINED, width: 600)
        }
      }
    }
  }
`;

export default Blog;