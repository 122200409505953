import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPostsPagination from "../components/blog/BlogPostsPagination"



const Blog = ({ data }) => {
    // Example Usage
    const allUniquePosts = collectUniquePosts(data);

  function collectUniquePosts(data) {
    const postContainers = [
      "LatestPost",
      "MorePosts",
      "RecommendPosts",
      "Category1",
      "Category2",
      "Category3",
    ];

    const uniquePostsMap = new Map(); // To track unique posts by ID

    postContainers.forEach(container => {
      if (data[container]) {
        // Handle edge-based containers
        if (data[container].edges) {
          data[container].edges.forEach(edge => {
            const post = edge.node;
            if (post && !uniquePostsMap.has(post.id)) {
              uniquePostsMap.set(post.id, post);
            }
          });
        }
        // Handle node-based containers
        else if (data[container].posts && data[container].posts.nodes) {
          data[container].posts.nodes.forEach(post => {
            if (post && !uniquePostsMap.has(post.id)) {
              uniquePostsMap.set(post.id, post);
            }
          });
        }
      }
    });

    return Array.from(uniquePostsMap.values());
  }

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 md:py-16 py-8 mt-10">                
          <BlogPostsPagination data={allUniquePosts} />
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Blog" />

export default Blog

export const blogTemplateQuery = graphql`
  query BlogTemplateQuery{     
    Category3: wpCategory(slug: {eq: "cloud-computing"}) {
          name
          slug
          posts {
            nodes {
              ...WpPostFragment
            }
          }
    }
    Category2: wpCategory(slug: {eq: "cost-management"}) {
          name
          slug
          posts {
            nodes {
              ...WpPostFragment
            }
          }
    }
    Category1: wpCategory(slug: {eq: "aws"}) {
        name
        slug
        posts {
          nodes {
            ...WpPostFragment
          }
        }
  }
   RecommendPosts: allWpPost(filter: {}, sort: {date: DESC}, limit: 3) {
    edges {
      node {
          ...WpPostFragment
      }
    }
  } 
   LatestPost: allWpPost(filter: {}, sort: {date: DESC}, limit: 1) {
      edges {
        node {
          ...WpPostFragment
        }
      }
    }
    MorePosts: allWpPost(sort: {date: DESC}, limit: 50) {
      edges {
        node {
          ...WpPostFragment
        }
      }
    }
  }
`;