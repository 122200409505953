import React, {lazy} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedPostSection from "../components/blog/FeaturedPostSection"
import BlogSectionWithSearch from "../components/blog/BlogSectionWithSearch"

const CategoryBlogSection = lazy(() => import("../components/blog/CategoryBlogSection"))
const MoreBlogsSection = lazy(() => import("../components/blog/MoreBlogsSection"))
const CloudCostSection = lazy(() => import("../components/Elements/CloudCostSection"))

const IndexPage = ({ data }) => {
  const { LatestPost, MorePosts, RecommendPosts, Category1, Category2, Category3 } = data
  // const authorsList = AllAuthors.edges.filter((item)=>{
  //   return item.node.posts && item.node.posts.nodes && item.node.posts.nodes.length>0
  // })
  return (
    <Layout header="dark">
      <FeaturedPostSection data={LatestPost?.edges[0]?.node} />
      <BlogSectionWithSearch data={RecommendPosts} />
      <CategoryBlogSection data={Category1} />
      <CategoryBlogSection data={Category2} />
      <CategoryBlogSection data={Category3} />
      <MoreBlogsSection data={MorePosts}/>
      <CloudCostSection />
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Economize - Thoughts, Insights on cloud cost optimization" />

export default IndexPage

export const homeTemplateQuery = graphql`
  query HomeTemplateQuery{ 
    Category3: wpCategory(slug: {eq: "cloud-computing"}) {
          name
          slug
          posts {
            nodes {
              ...WpPostFragment
            }
          }
    }
    Category2: wpCategory(slug: {eq: "cost-management"}) {
          name
          slug
          posts {
            nodes {
              ...WpPostFragment
            }
          }
    }
    Category1: wpCategory(slug: {eq: "aws"}) {
        name
        slug
        posts {
          nodes {
            ...WpPostFragment
          }
        }
  }
   RecommendPosts: allWpPost(filter: {}, sort: {date: DESC}, limit: 3) {
    edges {
      node {
          ...WpPostFragment
      }
    }
  } 
   LatestPost: allWpPost(filter: {}, sort: {date: DESC}, limit: 1) {
      edges {
        node {
          ...WpPostFragment
        }
      }
    }
    MorePosts: allWpPost(sort: {date: DESC}, limit: 50) {
      edges {
        node {
          ...WpPostFragment
        }
      }
    }
  }
`;