import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MoreLikeSection = ({data}) => {
    return (
        <div className="w-full md:py-12 py-8">
            <div className="max-w-7xl mx-auto">
                <h2 className="sm:text-[40px] text-[28px] text-[#202837] leading-tight font1 tracking-tight mb-5">More Like this</h2>
                <div className="grid md:grid-cols-2 gap-6">
                    {data && data[0] && (
                        <div>
                            <Link to={`/${data[0]?.node.slug}`}><GatsbyImage image={getImage(data[0]?.node.featuredImage.node)} alt={data[0]?.node.title} /></Link>
                            <h2><Link to={`/${data[0]?.node.slug}`} className="sm:text-3xl text-2xl text-[#202837] leading-tight font1 tracking-tight my-4 inline-block no-underline">{data[0]?.node.title}</Link></h2>
                            <div className="flex items-center gap-3 flex-wrap">
                                {data[0].node.date && <p className="text-sm text-[#202837] mb-0 font1">{data[0].node.date}</p>} 
                                {data[0].node.author?.node?.name && <p className="text-sm text-[#4C5463] mb-0">by <Link to={`/author/${data[0].node.author?.node?.slug}`} className="text-sm text-[#4C5463] no-underline">{data[0].node.author?.node?.name}</Link></p>}
                            </div>
                        </div>
                    )}
                    
                    <div>
                        {data && data.length>1 && data.slice(1,4).map(({node},index)=>{
                            return(
                                <div className="border-b border-[#D0D5DD] pb-4 mb-5" key={index}>
                                    <h2><Link to={`/${node.slug}`} className="text-xl text-[#202837] leading-tight font1 tracking-tight mb-4 inline-block no-underline">{node.title}</Link></h2>
                                    <div className="flex items-center gap-3 flex-wrap">
                                    {node.date && <p className="text-sm text-[#202837] mb-0 font1">{node?.date}</p>} 
                                    {node.author?.node?.name && <p className="text-sm text-[#4C5463] mb-0">by <Link to={`/author/${node.author?.node?.slug}`} className="text-sm text-[#4C5463] no-underline">{node?.author?.node?.name}</Link></p>}
                                    </div>
                                </div>
                            )
                        })}                     
                    </div>
                </div>

            </div>
        </div>
    )
}
export default MoreLikeSection